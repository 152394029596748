import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import henceforthIPFS from "./henceforthIPFS";

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = "https://staging.everlens.io:3000/";

const API_FILE_ROOT_MEDIUM =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/images/medium/";
const API_FILE_ROOT_ORIGINAL =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/images/original/";
const API_FILE_ROOT_SMALL =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/images/small/";
const API_FILE_ROOT_AUDIO =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/audio/";
const API_FILE_ROOT_VIDEO =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/video/";
const API_FILE_ROOT_DOCUMENTS =
  "https://everlensv3.s3.eu-west-3.amazonaws.com/uploads/documents/";

const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("token", `${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
};

const Auth = {
  login: (info) => requests.post("Admin/login", info),
  signup: (info) => requests.post("signup", info),
  forgotPassword: (info) => requests.post("forgot-password", info),
  checkOtp: (info) => requests.post("check-email-otp", info),
  resetPassword: (info) => requests.post("reset/password", info),
  changePassword: (info) => requests.put("admin/change-password", info),
};

const Dashboard = {
  getPagnation: () =>
    requests.get(
      `Admin/dashboard?language=ENGLISH&limit=10`
    ),
  getTradingPriceById: (graph_type) =>
    requests.get(`Admin/dashboard/graph?graph_type=${String(graph_type ? graph_type : "last_7_days").toUpperCase()}&language=ENGLISH`),
};

const Facility = {
  getApprovalPagnation: (page, limit, filter) =>
    requests.get(
      `admin/facility/approvals?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  getPagnation: (page, limit, filter) =>
    requests.get(
      `admin/facilities?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  approvalAccept: (facility_id) =>
    requests.put("admin/facility/approval", {
      facility_id: facility_id,
      status: 1,
    }),
  approvalDecline: (facility_id) =>
    requests.put("admin/facility/approval", {
      facility_id: facility_id,
      status: 4,
    }),
  updateFee: (item) => requests.put("admin/facility/fees", item),
};

const Transaction = {
  getPagnation: (page, limit, filter) =>
    requests.get(
      `admin/transactions?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
};

const PromoCode = {
  getPagnation: (page, limit, filter) =>
    requests.get(
      `admin/transactions?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  createEndUser: (item) => requests.post("admin/promo_code", item),
};

const User = {
  export: (start_date, end_date, timezone) =>
    requests.get(
      `Admin/users?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
    ),

  getListing: (search, pagination, limit) => {
    return requests.get(
      `Admin/users?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${pagination}&limit=${limit}`
    );
  },

  getTips: (_id, pagination) =>
    requests.get(
      `Admin/users/tip_history?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),

  getUserDetails: (_id) => requests.get(`Admin/users/${_id}?language=ENGLISH`),

  manageUsers: (values) =>
    requests.put("Admin/users", {
      type: values.type,
      _id: values._id,
      is_blocked: values.is_blocked,
      is_deleted: values.is_deleted,
      account_status: values.account_status,
      language: values.language,
      admin_verified: values.admin_verified ? values.admin_verified : false,
    }),

  sendCredit: (info) => requests.put("admin/credit", info),
  delete: (info) => requests.del("admin/user", info),
  getResaleNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=ON_SALE&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getOwnNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=OWNED&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getSoldNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=SOLD&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getCreatedNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=CREATED&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getBidNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=BIDS&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getFavNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=FAVOURITE&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getActivityNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=ACTIVITY&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
};

const Creator = {
  export: (start_date, end_date, timezone) =>
    requests.get(
      `Admin/creator?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
    ),
  reported: (_id, pagination) =>
    requests.get(
      `Admin/creator/reported?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getListing: (search, pagination) =>
    requests.get(
      `Admin/nfts?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${pagination}&limit=10`
    ),
  getCreatorDetails: (_id) =>
    requests.get(`Admin/nfts/details?${_id}&language=ENGLISH`),
  getTips: (_id, pagination) =>
    requests.get(
      `Admin/users/tip_history?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),
  // getPhotos: (creatorId) =>
  //   requests.get(`Admin/nfts/creator/${creatorId}?language=ENGLISH`),
  getCreatedNfts: (_id, pagination) =>
    requests.get(
      `Admin/nfts/creator?language=ENGLISH&type=CREATED&created_by=${_id}&pagination=${pagination}&limit=10`
    ),
  getOwnNfts: (_id, pagination) =>
    requests.get(
      `Admin/nfts/creator?language=ENGLISH&type=OWNED&created_by=${_id}&pagination=${pagination}&limit=10`
    ),
  getSoldNfts: (_id, pagination) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=SOLD&created_by=${_id}&pagination=${pagination}&limit=10`
    ),
  verifyProfile: (_id, admin_verified) =>
    requests.put("Admin/creator", {
      _id,
      type: "VERIFY/UNVERIFY",
      admin_verified: !admin_verified,
      language: "ENGLISH",
    }),
  manageCreators: (values) =>
    requests.put("Admin/creator", {
      type: values.type,
      _id: values._id,
      is_blocked: values.is_blocked,
      is_deleted: values.is_deleted,
      admin_verified: values.admin_verified,
      account_status: values.account_status,
      language: values.language,
    }),

  getMediaList: (_id, graph_type, timezone) =>
    // requests.get(`Admin/creator/${creatorId}?language=ENGLISH`),
    requests.get(
      `Admin/nfts/details?language=ENGLISH&_id=${_id}&graph_type=YEARLY&timezone=${timezone}`
    ),

  sendCredit: (info) => requests.put("admin/credit", info),
  delete: (info) => requests.del("admin/user", info),
};

const Listing = {
  getPagnation: (page, limit, filter) =>
    requests.get(
      `admin/listings?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
};

const Administrator = {
  getStaffMemberDetail: (id) =>
    requests.get(`Admin/staff_members/${id}?language=ENGLISH`),

  addStaffMembers: (values) => {
    console.log("values", values);

    return requests.post(`Admin/staff_members`, values);
  },
  blockOrDeleteStaff: (values) => requests.put("Admin/staff_members", values),

  getStaffMembers: (values) =>
    requests.get(
      `Admin/staff_members?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`
    ),

  getPagnation: (page, limit, filter) =>
    requests.get(
      `admin/administration?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  changeRole: (info) => requests.put("admin/role", info),

  changePassword: (values) =>
    requests.put("Admin/change_password", {
      old_password: values.oldPass,
      new_password: values.newPass,
      language: values.language,
    }),

  editImgProfile: (values, image) =>
    requests.put(`Admin/profile`, {
      image: image,
      name: values.name,
      language: values.language,
    }),

  editProfile: (values) =>
    requests.put(`Admin/profile`, {
      name: values.name,
      image: values.image,
      language: values.language,
    }),

  sendNotifications: (values) => requests.post(`Admin/notification`, values),
};

const Staff = {
  pagination: (page, search, role) =>
    requests.get(
      `Admin/staff_members?language=ENGLISH${search ? `&search=${search}` : ""
      }${role !== "all" ? `&role=${role}` : ""}&pagination=${Number(page) - 1
      }&limit=10`
    ),
};

const Policies = {
  all: () => requests.get(`Admin/policies?language=ENGLISH`),
  byType: (type) =>
    requests.get(`Admin/policies?language=ENGLISH&type=${type}`),
  addEditPolicy: (values) => requests.post(`Admin/policies`, values),
  delete: (id) => requests.del(`Admin/policies/${id}`),
};

const ContactUs = {
  pagination: (page, search) =>
    requests.get(
      `Admin/contact_us?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${Number(page)}&limit=6`
    ),
  fetchContacts: (values) =>
    requests.get(
      `Admin/contact_us?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`
    ),

  deleteContact: (values) => requests.put(`Admin/contact_us`, values),

  resolveContact: (values) => requests.put(`Admin/contact_us`, values),
};

const Profile = {
  get: () => requests.get(`admin/profile`),
  getById: (id) => requests.get(`profile?id=${id}`),
  update: (info) => requests.put(`admin/profile`, info),
};

const Faq = {
  addFaq: (values) => requests.post(`Admin/faqs`, values),
  editFaq: (values) => requests.post(`Admin/faqs`, values),
  pagination: (page, search) =>
    requests.get(
      `Admin/faqs?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${Number(page) - 1}&limit=8`
    ),
  getFaqById: (values) =>
    requests.get(`Admin/faqs?language=${values.language}&_id=${values._id}`),
  deleteFaq: (id) => requests.del(`Admin/faqs/${id}`),
};
const Reports = {
  pagination: (pagination) =>
    requests.get(
      `Admin/reported?language=ENGLISH&pagination=${pagination}&limit=10`
    ),
  getFaqById: (values) =>
    requests.get(`Admin/reported?language=ENGLISH&_id=${values._id}`),
};

const Common = {
  acceptRejectRequest: (values) => requests.put(`Admin/tiktok`, values),

  gettiktokrequest: (page, search) =>
    requests.get(
      `Admin/tiktok?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${Number(page)}&limit=10`
    ),
  ethusd: () =>
    superagent.get(
      `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD`
    ),
  allUsers: (search) =>
    requests.get(
      `Admin/users?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=0&limit=10`
    ),
  staticData: () => requests.get(`static-data`),
  getSales: (pagination, search) =>
    requests.get(
      `Admin/sales?language=ENGLISH&${search ? `&search=${search}` : ""
      }&pagination=${pagination}&limit=10`
    ),
  export: (start_date, end_date, timezone) =>
    requests.get(
      `Admin/sales?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
    ),
  getSalesDetails: (_id, pagination) =>
    requests.get(
      `Admin/sales?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),
  all_languages: () => requests.get(`admin/all_languages`),
  all_counrties: () => requests.get(`admin/all_counrties`),
  addCountries: (name) => requests.post(`admin/countries`, { name: name }),
  addLanguage: (name) => requests.post("admin/languages", { name: name }),
  backupDb: (bodyData, config) =>
    requests.post("Admin/backup", bodyData, config),
};
const Message = {
  send: (items) => requests.post("admin/message", items),
};

const Nfts = {
  getTradingPriceById: (graph_type) =>
    requests.get(`Admin/dashboard/graph?graph_type=${String(graph_type).toUpperCase()}&language=ENGLISH`),
  export: (start_date, end_date, timezone) =>
    requests.get(
      `Admin/nfts?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
    ),

  pagination: (pagination, search, nft_type) =>
    requests.get(
      `Admin/nfts?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=${pagination}&nft_type=${nft_type}&limit=9`
    ),

  reports: (_id) =>
    requests.get(`Admin/nfts/reported?language=ENGLISH&nft_id=${_id}`),
  tradingHistorynft: (_id) =>
    requests.get(`Admin/nfts/${_id}/trading_history?language=ENGLISH`),
  allReports: (_id) => requests.get(`Admin/nfts/reported?language=ENGLISH`),
  details: (_id) =>
    requests.get(
      `Admin/nfts/${_id}?language=ENGLISH`
    ),
  delete: (info) => requests.put(`Admin/nfts`, info),
  recovery: (info) => requests.put(`Admin/nfts/recovery`, info),
};

const Weekly = {
  add: (value) =>
    requests.post(`Admin/nfts/weekly`, value),
  remove: (value) =>
    requests.put(`Admin/nfts/weekly`, value),
  pagination: (nft_type, pagination) =>
    requests.get(
      `Admin/nfts/weekly?limit=10&nft_type=${nft_type}&pagination=${pagination}&language=ENGLISH`
    ),
}

const FILES = {
  audio: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_AUDIO}${filename}` : henceforthIPFS.getWithIPFS(filename),
  video: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_VIDEO}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageOriginal: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_ORIGINAL}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageMedium: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_MEDIUM}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageSmall: (filename) => filename?.includes(".") ? `${API_FILE_ROOT_SMALL}${filename}` : henceforthIPFS.getWithIPFS(filename),
}
export default {
  FILES,
  token,
  ContactUs,
  Faq,
  Reports,
  Auth,
  Administrator,
  Staff,
  Common,
  Profile,
  Dashboard,
  Facility,
  Listing,
  Transaction,
  PromoCode,
  User,
  Policies,
  Creator,
  Message,
  Weekly,
  Nfts,
  API_ROOT,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  setToken: (_token) => {
    token = _token;
  },
};
